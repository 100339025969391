import { Link } from "gatsby"
import PropTypes from "prop-types"
import React, { Component } from "react"

import {
    followText,
    headerCls,
    icon,
    allLinks,
    menuButton,
    name,
    navigation,
    navigationButton,
    open,
    popMenu,
    popMenuContent,
    siteTitle,
    socialLinks
} from "./header.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
    faRocket,
    faGraduationCap,
    faEnvelope,
    faBars,
    faTimes,
    faMicrophone, faNewspaper
} from "@fortawesome/free-solid-svg-icons"
import { faTwitter, faGithub, faGoogle, faYoutube, faLinkedin } from "@fortawesome/free-brands-svg-icons"

const links =
    [
        {
            url: "/",
            icon: faRocket,
            name: "Projects"
        },
        {
            url: "/academic",
            icon: faGraduationCap,
            name: "Academic"
        },
        {
            url: "/talks",
            icon: faMicrophone,
            name: "Talks"
        },
        {
            url: "/press",
            icon: faNewspaper,
            name: "Press"
        },
        {
            url: "/contact",
            icon: faEnvelope,
            name: "Contact"
        }
    ]
const social =
    [
        {
            url: "https://twitter.com/thomas_wint",
            icon: faTwitter,
            name: "Twitter"
        },
        {
            url: "https://github.com/twinters",
            icon: faGithub,
            name: "GitHub"
        },
        {
            url: "https://www.youtube.com/thomaswinters",
            icon: faYoutube,
            name: "Youtube"
        },
        {
            url: "https://scholar.google.com/citations?user=lHfPiwoAAAAJ",
            icon: faGoogle,
            name: "Google Scholar"
        },
        {
            url: "https://www.linkedin.com/in/thomas-winters/",
            icon: faLinkedin,
            name: "Linkedin"
        }
    ]

const getNavigationLinks = (action) => (
    <ul className={navigation}>
        {links.map(l => (
            <li key={l.name} className={navigationButton}>
                <Link to={l.url}
                      onClick={() => action ? action() : ""}
                      className={"no-underline"}>
                    <FontAwesomeIcon icon={l.icon}
                                     className={"fa-lg fa-fw " + icon}/>
                    <span>{l.name}</span>
                </Link>
            </li>
        ))}

    </ul>
)
const getSocialLinks = () => (
    <ul className={socialLinks}>
        {social.map(l => (
            <li key={l.name} className={navigationButton}>
                <a href={l.url} target="_blank" rel="noopener noreferrer">
                    <FontAwesomeIcon icon={l.icon} className={"fa-lg fa-fw " + icon}/>
                </a>
            </li>
        ))}

    </ul>
)

class Header extends Component {
    constructor(props) {
        super(props)
        this.state = {
            open: false
        }
    }

    isOpen() {
        return this.state.open
    }

    close() {
        this.setState({ open: false })
    }

    open() {
        this.setState({ open: true })
    }

    toggle() {
        if (this.isOpen()) {
            this.close()
        } else {
            this.open()
        }
    }

    render() {
        return <header>
            <div className={headerCls}>
                <h1 className={name}>
                    <Link to="/" className={siteTitle}>
                        {this.props.siteTitle}
                    </Link>
                </h1>

                <div className={navigationButton + " " + menuButton}>
                    {!this.isOpen() && <button onClick={() => this.open()}
                                               tabIndex="0">
                        <FontAwesomeIcon icon={faBars} className={"fa-lg fa-fw " + icon}/>
                    </button>}
                    {this.isOpen() && <button onClick={() => this.close()}
                                              tabIndex="0">
                        <FontAwesomeIcon icon={faTimes} className={"fa-lg fa-fw " + icon}/>
                    </button>}
                </div>


                <div className={allLinks}>
                    {getNavigationLinks()}
                    {getSocialLinks()}
                </div>

            </div>
            <div className={`${popMenu} ${this.state.open ? `${open}` : ""}`}>
                <div className={popMenuContent}>
                    {getNavigationLinks(this.close.bind(this))}
                    <span className={followText}>Follow me on social media</span>
                    {getSocialLinks()}
                </div>
            </div>

        </header>
    }
}

Header.propTypes = {
    siteTitle: PropTypes.string
}

Header.defaultProps = {
    siteTitle: ``
}

export { Header, social }
