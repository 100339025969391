exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-academic-js": () => import("./../../../src/pages/academic.js" /* webpackChunkName: "component---src-pages-academic-js" */),
  "component---src-pages-appearances-js": () => import("./../../../src/pages/appearances.js" /* webpackChunkName: "component---src-pages-appearances-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-contact-success-js": () => import("./../../../src/pages/contact-success.js" /* webpackChunkName: "component---src-pages-contact-success-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-pictures-js": () => import("./../../../src/pages/pictures.js" /* webpackChunkName: "component---src-pages-pictures-js" */),
  "component---src-pages-press-js": () => import("./../../../src/pages/press.js" /* webpackChunkName: "component---src-pages-press-js" */),
  "component---src-pages-talks-js": () => import("./../../../src/pages/talks.js" /* webpackChunkName: "component---src-pages-talks-js" */),
  "component---src-templates-overview-js": () => import("./../../../src/templates/overview.js" /* webpackChunkName: "component---src-templates-overview-js" */),
  "component---src-templates-paper-js": () => import("./../../../src/templates/paper.js" /* webpackChunkName: "component---src-templates-paper-js" */),
  "component---src-templates-post-js": () => import("./../../../src/templates/post.js" /* webpackChunkName: "component---src-templates-post-js" */),
  "component---src-templates-project-js": () => import("./../../../src/templates/project.js" /* webpackChunkName: "component---src-templates-project-js" */),
  "component---src-templates-project-tag-js": () => import("./../../../src/templates/project-tag.js" /* webpackChunkName: "component---src-templates-project-tag-js" */),
  "component---src-templates-talk-js": () => import("./../../../src/templates/talk.js" /* webpackChunkName: "component---src-templates-talk-js" */),
  "component---src-templates-talk-type-js": () => import("./../../../src/templates/talk-type.js" /* webpackChunkName: "component---src-templates-talk-type-js" */)
}

